<template>
  <div class="items">
    <div class="slot">
      <!--  solution for "Cannot read properties of undefined (reading 'removeEventListener')" from https://github.com/nolimits4web/swiper/issues/4043 -->
      <NuxtErrorBoundary @error="() => {}">
        <Swiper
          :slides-per-view="2"
          :breakpoints="{
            800.001: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }"
          class="swiper-wrapper"
          @after-init="initSliderInstance"
          @slide-change="updateSliderInfo"
          @resize="updateSliderInfo"
        >
          <SwiperSlide v-for="item in content?.items" class="item">
            <component
              :is="config?.type == 'TeaserBox' ? C_TeaserBox : 'span'"
              :content="item.content"
              :config="item.config"
              :layout="item.layout"
              :banner="item.banner"
              :text="item.text"
              :fig="item.fig"
              :like="item.like"
            />
          </SwiperSlide>
        </Swiper>
      </NuxtErrorBoundary>
    </div>
  </div>
</template>

<script lang="ts" setup>
import C_TeaserBox from '@components/Teaser/Box/Box.vue';
import type { Swiper } from 'swiper';
import type { Items } from './models';

const swiperInstance = ref<Swiper | null>(null);
// used to store the info object that is passed to the SliderControls component
const sliderInfo = ref({
  label: '',
  hasNext: false,
  hasPrev: false,
});

const { content, config } = defineProps<Items>();

const emit = defineEmits(['swiperUpdate']);

const initSliderInstance = (swiper: Swiper) => {
  setSwiperInstance(swiper);
  addKeyboardNavigation();
};
// called on Swiper @afterInit to store the Swiper Instance
const setSwiperInstance = (swiper: Swiper) => {
  swiperInstance.value = swiper;
};

// add focus event listener to all links in the slides to allways slide to the slide that is currently :focus-within
const addKeyboardNavigation = () => {
  swiperInstance.value?.slides.forEach((slide, index) => {
    Array.from(slide.querySelectorAll('a')).forEach((link) => {
      link.addEventListener('focus', () => {
        swiperInstance.value?.slideTo(index);
      });
    });
  });
};

const updateSliderInfo = () => {
  const swiper = swiperInstance.value;

  if (swiper && !swiper?.destroyed) {
    const currentPosition = swiper.activeIndex + 1;
    const itemCount = content?.items?.length ?? 0;

    sliderInfo.value = {
      label: itemCount > 1 ? `${currentPosition} von ${itemCount}` : '',
      hasNext: !swiper.isEnd,
      hasPrev: !swiper.isBeginning,
    };
  } else {
    sliderInfo.value = {
      label: '',
      hasNext: false,
      hasPrev: false,
    };
  }

  emit('swiperUpdate', sliderInfo.value);
};

defineExpose({
  swiperInstance,
});
</script>

<style src="./Items.scss" scoped lang="scss"></style>
