<template>
  <section class="sliderTeaser">
    <div class="global-grid">
      <div class="sliderTeaserWrap">
        <SliderTeaserTitle
          v-if="!isEmpty(title)"
          :content="title.content"
          :config="title.config"
          :layout="title.layout"
        />

        <SliderTeaserItems
          v-if="!isEmpty(items)"
          ref="slider"
          :content="items.content"
          :config="items.config"
          :layout="items.layout"
          @swiper-update="(e) => (sliderInfo = e)"
        />

        <SliderPagination
          v-if="!isEmpty(items)"
          :swiper-instance="slider?.swiperInstance"
          :slider-info="sliderInfo"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Items } from './Items/models';
import type { SliderTeaser } from './models';
import isEmpty from '@utils/isEmpty';

const slider = ref<Items | null>(null);

const { title, items } = defineProps<SliderTeaser>();

const sliderInfo = ref<{
  label: string;
  hasNext: boolean;
  hasPrev: boolean;
} | null>(null);
</script>

<style src="./Teaser.scss" scoped lang="scss"></style>
