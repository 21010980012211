<template>
  <article class="teaserBox">
    <TeaserBoxFig
      :content="fig?.content"
      :config="fig?.config"
      :layout="fig?.layout"
      :fig-fallback="fig?.figFallback"
    />

    <TeaserBoxText
      :content="text?.content"
      :config="text?.config"
      :layout="text?.layout"
      :datelist="text?.datelist"
      :info-tags="text?.infoTags"
      :post-address="text?.postAddress"
      :title="text?.title"
    />

    <TeaserListBanner
      :content="banner?.content"
      :config="banner?.config"
      :layout="banner?.layout"
    />

    <TeaserBoxLike
      v-if="widgetConfig?.quickAccessList"
      :content="like?.content"
      :config="{ ...like?.config, isActive: isLiked }"
      :layout="like?.layout"
      @click="toggleLike"
    />
  </article>
</template>

<script lang="ts" setup>
import type { Box } from './models';

const widgetConfig = await useWidgetConfig();
const bookmarkStore = useBookmarkStore();

const { banner, config, fig, like, text } = defineProps<Box>();

const itemKey = computed(() => `${config?.moduleType}_${config?.id}`);

const isLiked = computed(() => {
  if (!config?.moduleType || !config?.id) return false;

  return bookmarkStore.contains(toValue(itemKey));
});

const toggleLike = () => {
  if (!config?.moduleType || !config?.id) return;

  if (isLiked.value) {
    bookmarkStore.removeBookmark(toValue(itemKey));
  } else {
    bookmarkStore.addBookmark(toValue(itemKey));
  }
};
</script>

<style src="./Box.scss" scoped lang="scss"></style>
