<template>
  <div
    v-if="sliderInfo?.label && (sliderInfo?.hasPrev || sliderInfo?.hasNext)"
    class="pagination"
  >
    <button
      v-if="sliderInfo?.hasPrev"
      :aria-label="$t('common.back')"
      class="button"
      @click="slidePrev"
    >
      <BaseIcon class="icon" name="ion:arrow-back" />
    </button>

    <span class="info">{{ sliderInfo?.label }}</span>

    <button
      v-if="sliderInfo?.hasNext"
      :aria-label="$t('common.next')"
      class="button"
      @click="slideNext"
    >
      <BaseIcon class="icon" name="ion:arrow-forward" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { Swiper } from 'swiper';

const props = defineProps<{
  swiperInstance: Swiper;
  sliderInfo: {
    label: string;
    hasNext: boolean;
    hasPrev: boolean;
  } | null;
}>();

const slideNext = () => {
  props.swiperInstance?.slideNext();
};
const slidePrev = () => {
  props.swiperInstance?.slidePrev();
};
</script>

<style src="./Pagination.scss" scoped lang="scss"></style>
